import {createRoot} from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./styles/global.css";
import "./styles/waves.scss";
import "./styles/main.scss";
import "./styles/extras.scss";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
